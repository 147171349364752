<template>
<v-row>
    <v-col>
      <base-card>
        <v-card-title>Perfiles</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="5">
                       <v-text-field
                                v-model="perfil.profile"
                                label="Nombre del perfil"
                                class="uppercase"
                                
                            />
                </v-col>
            </v-row>
      
 
       <v-row>
                 <v-checkbox
            label="Sistema"
          />
                 </v-row>
       <v-row>

           <v-checkbox
            label="Catálogos"
          />
                 </v-row>
       <v-row>

           <v-checkbox
          
            label="Operación"
          />
       </v-row>
       <v-row>
           <v-col cols="4">
              <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Búsqueda"
                        single-line
                        hide-details
                    ></v-text-field>
           </v-col>
       </v-row>
              <v-row>
                  
                   <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_perfiles"
                    item-key="idperfil"
                    
                    class="elevation-1 table-one"
                    multi-sort
                >
                   </v-data-table>
 
                  </v-row>   
           
          <v-row>
            <v-col cols="4">
              <v-btn color="success">
                  <v-icon>mdi-content-save-outline</v-icon>
                Guardar
              </v-btn>
            </v-col>
        <v-col cols="4" v-if="edit">
         
              <v-btn color="danger">
                  <v-icon>mdi-close-circle-outline</v-icon>
                Cancelar Edición
              </v-btn>
            </v-col>
          </v-row>
          <br />


         </v-card-text>
      </base-card>
    </v-col>
</v-row> 
         
       
</template>
<script>


import { url } from "../../main.js";

export default {
  data() {
    return {
        search:'',
         headers: [
                {
                    text: 'Perfil',
                    align: 'start',
                    sortable: false,
                    value: 'profile'
                },
         ],
      perfil: {
       
        idprofile: 0,
        perfil:''
      },
      edit: false,
      nombre: "",

      busqueda_nombre: "",
      lista_perfiles: []
    
    }
  },
  mounted(){
console.log("mounted");
    this.get_perfiles();

  },
  components: {
    
  },

  computed: {
    
  },

  methods: {
    get_perfiles() {
      this.axios
        .get(url + "apiPerfiles.php/perfiles")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_perfiles = respuesta.data;

          this.usuario.idprofile = this.lista_perfiles[0].idprofile;
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    saveUsuario() {},

    limpiarcampos() {
      /*  this.cliente = {};
      this.nombre_cliente="";*/
    },
    validarcampos() {
      /* if (
        this.cliente.nombre == "" ||
        this.cliente.apellido == "" 
        //||
       // this.cliente.telefono == ""
      ) {
        return false;
      } else {
        return true;
      }*/
    },
    getidProfile(e) {
      if (e.target.options.selectedIndex > -1) {
        this.usuario.idprofile = this.lista_perfiles[
          e.target.options.selectedIndex
        ].idprofile;
      }
    },
    clear_busqueda_perfil() {
      this.busqueda_nombre = "";
    },

    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage * this.pageSize < this.lista_clientes.length)
        this.currentPage++;
    },
    sort(s) {
      console.log("sort");
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    editPerfil(idperfil) {
      this.axios
        .get(url + "apiPerfiles.php/get_perfil/" + idperfil)
        .then((respuesta) => {
          console.log(respuesta);
          this.cliente = respuesta.data;
          this.edit = true;
        });
    },
    eliminarPerfil(perfil) {
      this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar el perfil?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(url + "apiClientes.php/delete_cliente", cliente)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("Cliente Eliminado");
                this.verClientes();
              }
            });
        }
      });
    },

    cancelarEdicion() {
      this.edit = false;
      this.perfil = {};
    },
  },
};
</script>
